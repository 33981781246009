import axios from 'axios';
import store from '../store';

class RestApi {

   baseCalcServiceAPI: string = '';
   baseCalcBalanceAPI: string = '';
   tokenCreationAPI: string = ''
   tokenCredential: string = '';
   constructor() {
      if (process.env.REACT_APP_SERVER_ENV === 'development' || process.env.NODE_ENV === 'development') {
         this.baseCalcServiceAPI = "https://api.pilot.principal.com/liability-calc-service-azure/";
         //this.baseCalcServiceAPI = "https://api.pilot.principal.com/liability-calc-service/";
         this.baseCalcBalanceAPI = "https://9l0a2w8tvj.execute-api.us-east-1.amazonaws.com/prod/v1/history/";
         this.tokenCreationAPI = "https://accounts.staging.principal.com/oauth2/aus4nvp00mFyV36By697/v1/token";
         this.tokenCredential = 'dnlyR2VKM2NDRUptaFc4SXo3ZnpobGRGdTZQdDczaEE6RVhBeDF1djBkTEwyZmJ2Sw==';
      }
      else {
         this.baseCalcServiceAPI = "https://api.principal.com/liability-calc-service-azure/";
         this.baseCalcBalanceAPI = "https://hvf4svr71b.execute-api.us-east-1.amazonaws.com/prod/v1/history/";
         this.tokenCreationAPI = "https://accounts.principal.com/oauth2/aus8xm6hson7W0A385d7/v1/token";
         this.tokenCredential = "RWFBNnlKWHM2YThBSU5pTFpWNzlPdFVCQ3NrZ09mTDA6SG9VVGhpRFJlQ1BYRHFrUw=="
      }
   }

   getSubAccountBalances(contractId: string, enterpriseId: string, startDate: string, endDate: string): any {
      if (startDate === '' && endDate === '') {
         startDate = '1900-01-01';
         endDate = '9999-12-31';
      }

      if (startDate === '') {
         startDate = '1900-01-01';
      }

      if (endDate === '') {
         endDate = '9999-12-31';
      }

      let url = this.baseCalcBalanceAPI + `historicalSubaccount/event?contractId=${contractId}&enterpriseId=${enterpriseId}&effectiveStartDate=${startDate}&effectiveEndDate=${endDate}`;
      let headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("NQ_AZURE_AUTH_TOKEN"),
         'Access-Control-Allow-Headers': 'x-dtc'
      };

      // Make API call with url, data, and headers
      return axios.get(url, { headers: headers })
   }

   getAccounts(contractId: string, enterpriseId: string) {
      let url = this.baseCalcServiceAPI + `enterprise/${enterpriseId}/contract/${contractId}/accounts`;
      url = url.replace(":contractId", contractId);
      url = url.replace(":enterpriseId", enterpriseId);

      let headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("NQ_AZURE_AUTH_TOKEN"),
         'Consumer-Name': 'M022245',
         'Access-Control-Allow-Origin': '*'
      }
      return axios.get(url, { headers: headers });
   }

   getMoneyTypes(contractId: string) {
      let url = this.baseCalcServiceAPI + `contract/${contractId}/moneytypes`;
      let headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("NQ_AZURE_AUTH_TOKEN"),
         'Consumer-Name': 'M022245',
         'Access-Control-Allow-Origin': '*'
      };

      return axios.get(url, { headers: headers });
   }

   getUser(name: string) {
      let url = this.baseCalcServiceAPI + `user/${name}/name`;
      let headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("NQ_AZURE_AUTH_TOKEN"),
         'Consumer-Name': 'M022245',
         'Access-Control-Allow-Origin': '*'
      };

      return axios.get(url, { headers: headers });
   }

   getEventDetails(eventId: string, acntId: string, moneyType: string, eventType: string, lastChangeTs: string): any {
      let url = this.baseCalcServiceAPI + `history/historicalBalanceEventDetails/eventId/${eventId}`;
      let headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("NQ_AZURE_AUTH_TOKEN"),
         'accountid': acntId,
         'moneytypeid': moneyType,
         'eventtype': eventType,
         'lastchangetimestamp': lastChangeTs,
         'Consumer-Name': 'M022245',
         'Access-Control-Allow-Origin': '*'
      };

      // U63U5779W
      // let url = this.baseCalcServiceAPI + "history/historicalBalanceEventDetails/eventId/325727147";
      // 'Authorization': 'Bearer ' + localStorage.getItem("NQ_AZURE_AUTH_TOKEN"),
      // 'accountid': '305687',
      // 'moneytypeid': '3',
      // 'eventtype': 'transaction',
      // 'lastchangetimestamp': '2023-03-23 01:04:58.420',
      // 'Access-Control-Allow-Origin': '*'

      return axios.get(url, { headers: headers });
   }

   retrieveToken(): Promise<any> {
      return new Promise((resolve, reject) => {
         let $this = this;
         //if (!this.confirmValidToken()) {
         //TODO: add auth token to vault
         let auth = this.tokenCredential
         let url = this.tokenCreationAPI

         let data = {
            'grant_type': 'client_credentials',
         }
         let headers = {
            'Authorization': 'Basic ' + auth,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            // 'crossDomain': 'true',
            // 'xhrFields': 'withCredentials: true'
         };
         if (process.env.REACT_APP_SERVER_ENV === 'development' || process.env.REACT_APP_SERVER_ENV === 'local' || process.env.NODE_ENV === 'development') {
            axios.post(url, data, { headers: headers }).then(function (response) {
               store.dispatch({
                  type: 'SET_AWS_FORGEROCK_TOKEN',
                  payload: response.data.access_token
               })

               let expirationTime = $this.setExpirationTime(response.data.expires_in);
               console.log("setting token in local storage");
               localStorage.setItem("AWS_FORGEROCK_TOKEN", response.data.access_token);
               localStorage.setItem("AWS_FORGEROCK_TOKEN_EXPIRES", expirationTime);
               resolve(response.data.access_token);
            })
               .catch(function (error) {
                  console.log(error);
                  reject(error);
               });
         }
         else {
            let prodBody = {
               'grant_type': 'client_credentials',
            }
            let prodHeaders = {
               "Authorization": "Basic " + this.tokenCredential,
               'Content-Type': 'application/x-www-form-urlencoded',
               'Access-Control-Allow-Origin': '*'
            }
            axios.post(url, prodBody, { headers: prodHeaders }).then(function (response) {
               store.dispatch({
                  type: 'SET_AWS_FORGEROCK_TOKEN',
                  payload: response.data.access_token
               })

               let expirationTime = $this.setExpirationTime(response.data.expires_in);
               console.log("setting token in local storage");
               localStorage.setItem("AWS_FORGEROCK_TOKEN", response.data.access_token);
               localStorage.setItem("AWS_FORGEROCK_TOKEN_EXPIRES", expirationTime);
               resolve(response.data.access_token);
            })
               .catch(function (error) {
                  console.log(error);
                  reject(error);
               });
         }

      });
   }

   confirmValidToken(): boolean {
      let expirationTime = localStorage.getItem("NQ_HIST_AUTH_TOKEN_EXPIRES");
      if (expirationTime == null) {
         return false;
      }

      let expirationNum = Number.parseInt(expirationTime);
      let currTime = new Date().getTime();
      let valid = localStorage.getItem("NQ_HIST_AUTH_TOKEN") != null && currTime < expirationNum;

      return localStorage.getItem("NQ_HIST_AUTH_TOKEN") != null && currTime < expirationNum;
   }

   clearTokenCache(): void {
      localStorage.setItem("NQ_HIST_AUTH_TOKEN", '');
      localStorage.setItem("NQ_HIST_AUTH_TOKEN_EXPIRES", '');
      localStorage.setItem("NQ_AZURE_AUTH_TOKEN", '');
      localStorage.setItem("NQ_AZURE_AUTH_TOKEN_EXPIRES", '');
   }

   setExpirationTime(ttl: number): string {
      let currTime = new Date().getTime();
      let expirationTime = new Date(currTime + ttl * 1000).getTime();

      return expirationTime + '';
   }
}

export default RestApi;
